import React, { FC } from "react";
import { Header, HeaderProps } from "../components/Header/Header";
import { usePortalProvider } from "../providers/portal-provider";

export const HeaderContainer: FC<HeaderProps> = (props) => {
  const { noHeader } = usePortalProvider();

  if (noHeader) return null;

  return <Header {...props} />;
};
