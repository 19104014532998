import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  NotAuthorized,
  NotAuthorizedProps,
} from "../components/EmptyState/NotAuthorized";

export const NotAuthorizedContainer: FC = () => {
  const { push } = useHistory();

  const action = useCallback(() => {
    push("/");
  }, [push]);

  const props: NotAuthorizedProps = {
    action,
  };

  return <NotAuthorized {...props} />;
};
