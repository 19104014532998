import React, {
  ChangeEventHandler,
  DetailedHTMLProps,
  FC,
  KeyboardEventHandler,
  useCallback,
  useMemo,
} from "react";
import classNames from "classnames";
import SearchIcon from "@mui/icons-material/Search";

export interface SearchInputProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  onClickSearch: any;
  onChangeText: (text: string) => void;
}

export const SearchBar: FC<SearchInputProps> = ({
  className,
  onChangeText,
  onClickSearch,
  value,
  placeholder = "Buscar...",
  ...inputProps
}) => {
  const wrapperClassName = useMemo(
    () =>
      classNames({
        "xvia-container-search-input": true,
        [className as string]: !!className,
      }),
    [className]
  );

  const textChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const newValue = event.target.value;

      if (value !== newValue) {
        onChangeText(newValue);
      }
    },
    [onChangeText, value]
  );

  const keyDownHandler = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") {
        onClickSearch(event);
      }
    },
    [onClickSearch]
  );

  return (
    <div className={wrapperClassName}>
      <div className="xvia-search-input__block">
        <div className="xvia-search-input__inputContainer">
          <SearchIcon />
          <span>|</span>
          <input
            onChange={textChangeHandler}
            onKeyDown={keyDownHandler}
            className="xvia-search-input__input"
            value={value}
            type="text"
            name="keyword"
            aria-label="Campo de busca"
            placeholder={placeholder}
            {...inputProps}
          />
        </div>
        <div className="xvia-search-input__button-wrapper">
          <button onClick={onClickSearch}>Procurar</button>
        </div>
      </div>
    </div>
  );
};
