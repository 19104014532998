import React, { FC } from "react";
import {
  FacebookFilled,
  TwitterSquareFilled,
  InstagramFilled,
  YoutubeFilled,
} from "@ant-design/icons";

import HomeIcon from "@mui/icons-material/Home";

import { ReactComponent as NetworkIcon } from "../../assets/icons/network.svg";
import { ReactComponent as LetterIcon } from "../../assets/icons/letter.svg";

import { ExternalLink } from "../Link/ExternalLink";

export const TopBar: FC = () => (
  <div className="xvia-header-topbar">
    <div className="xvia-header-info">
      <div className="xvia-header-container">
        <div className="xvia-header-quick-links">
          <ExternalLink link="">
            <InstagramFilled />
          </ExternalLink>

          <ExternalLink link="">
            <FacebookFilled />
          </ExternalLink>

          <ExternalLink link="">
            <YoutubeFilled />
          </ExternalLink>

          <ExternalLink link="">
            <TwitterSquareFilled />
          </ExternalLink>
        </div>
        <div className="xvia-header-links">
          <span className="xvia-header-separator-links">|</span>
          <ExternalLink link="/app/catalog">
            <HomeIcon />
            <span>Início</span>
          </ExternalLink>

          <span className="xvia-header-separator-links">|</span>
          <ExternalLink link="/app/catalog/secretarias-e-orgaos">
            <NetworkIcon />
            <span>Secretarias e órgãos</span>
          </ExternalLink>

          <span className="xvia-header-separator-links">|</span>
          <ExternalLink link="/app/catalog/carta-de-servicos">
            <LetterIcon />
            <span>Cartas de Serviços</span>
          </ExternalLink>

          {/* <span className="xvia-header-separator-links">|</span>
        <ExternalLink link="/app/catalog/orgao">
          <NewspaperIcon />
          <span>Notícias</span>
        </ExternalLink>

        <span className="xvia-header-separator-links">|</span>
        <ExternalLink link="/app/catalog/orgao">
          <FlagIcon />
          <span>O Governo</span>
        </ExternalLink>

        <span className="xvia-header-separator-links">|</span>
        <ExternalLink link="/app/catalog/orgao">
          <PhoneIcon />
          <span>Informações</span>
        </ExternalLink> */}
        </div>
      </div>
    </div>
  </div>
);
