import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { NotFound, NotFoundProps } from "../components/EmptyState/NotFound";

export const NotFoundContainer: FC = () => {
  const { push } = useHistory();

  const action = useCallback(() => {
    push("/");
  }, [push]);

  const props: NotFoundProps = {
    action,
  };

  return <NotFound {...props} />;
};
