import React, { FC } from "react";
import { TopBar } from "./TopBar";

export interface HeaderProps {
  rightElement: React.ReactElement;
}

export const Header: FC<HeaderProps> = (props) => (
  <header className="xvia-header-area">
    <div className="xvia-header-spacing">
      <div className="xvia-header-logo">
        <a href="/">
          <img
            src="/assets/img/logo/xvia-white.png"
            alt="Logo da X-Via"
            width={125}
          />
        </a>
      </div>
      <div className="xvia-header-elements">{props.rightElement}</div>
    </div>
    <TopBar />
  </header>
);
