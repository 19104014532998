import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  GenericError,
  GenericErrorProps,
} from "../components/EmptyState/GenericError";

export const GenericErrorContainer: FC = () => {
  const { push } = useHistory();

  const action = useCallback(() => {
    push("/");
  }, [push]);

  const props: GenericErrorProps = {
    action,
  };

  return <GenericError {...props} />;
};
